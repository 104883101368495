.footer {
    width: 100%;
    height: auto; /* Yüksekliği otomatik ayarlıyoruz */
    color: #666;
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem; /* Padding ekleyerek alanı artırıyoruz */
    font-weight: 300;
    font-size: 0.9rem;
    text-align: center; /* Metni ortalıyoruz */
}

/* Mobil görünüm için */
@media (max-width: 768px) {
    .footer {
        font-size: 0.8rem; /* Daha küçük yazı boyutu */
        padding: 0.5rem; /* Daha az padding */
    }
}
